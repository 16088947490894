import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 108 108">
    <title>Logo</title>
    <g transform="translate(2, 2) scale(0.96)">
      <path
        d="M 20 20 V 80 H 30 V 45 L 70 80 V 37 H 60 V 57 L 20 20 M 45 30 L 91 30 L 99 36 L 99 64 L 94 70 L 77 70 L 77 80 L 99 80 L 108 69 V 29 L 96 20 H 34 L 45 30 Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default IconLogo;