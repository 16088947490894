import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 1500">
    <title>Loader Logo</title>
    <g id="B" transform="translate(52, 95) scale(12)">
      <path
        d="M 20 20 V 80 H 30 V 45 L 70 80 V 37 H 60 V 57 L 20 20 M 45 30 L 91 30 L 99 36 L 99 64 L 94 70 L 77 70 L 77 80 L 99 80 L 108 69 V 29 L 96 20 H 34 L 45 30 Z"
        fill="var(--blue)"
        stroke="var(--blue)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default IconLoader;
